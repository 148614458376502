import * as React from "react";
import * as carouselStyles from "./carousel.module.scss";
import { Carousel as CarouselComponent } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import product from "../../images/Product-SKU.webp";
import { graphql, StaticQuery } from "gatsby";
import CarouselItem from "./carousel-item";
import { CarouselProps, CarouselWrapper } from "./types";
import Container from "../container/container";
import RightArrow from "../../images/chevron_right_black_24dp.svg";

const CarouselComp: React.FC<CarouselProps> = ({ items }: CarouselProps) => {
  /*
        <div className={carouselStyles.product}>
        <img src={product} alt="gorsel" width="204" height="129" />
      </div>
   */
  return (
    <Container fullWidth className={carouselStyles.container}>
      <div className={carouselStyles.container}>
        <CarouselComponent
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          autoPlay={false}
          interval={4000}
          transitionTime={500}
          dynamicHeight={false}
          infiniteLoop={true}
          showIndicators={false}
          renderArrowPrev={(onClickHandler, index) => {
            return (
              <div className={carouselStyles.leftarrow}>
                <button onClick={onClickHandler}
                ><img
                    src={RightArrow}
                    alt="ok"
                    width="24"
                    height="24"
                  />
                </button>
              </div>
            )
          }
          }
          renderArrowNext={(onClickHandler, index) => {
            return (
              <div className={carouselStyles.rightarrow}>
                <button onClick={onClickHandler}
                ><img
                    src={RightArrow}
                    alt="ok"
                    width="24"
                    height="24"
                  />
                </button>
              </div>
            )
          }
          }
        >
          {items?.map((node) => (
            <CarouselItem key={node.id} item={node} />
          ))
          }
        </CarouselComponent>
      </div>
    </Container >
  );
};
export const Carousel: CarouselWrapper = (props) => (
  <StaticQuery
    query={graphql`
      query HomepageCarouselQuery {
        allHomepageCarouselJson {
          nodes {
            title
            id
            description
            imageDesktop {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, breakpoints: [1920])
              }
            }
            imageTablet {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, breakpoints: [768])
              }
            }
            imageMobile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, breakpoints: [1000])
              }
            }
            link {
              path
              text
            }
          }
        }
      }
    `}
    render={(data) => (
      <CarouselComp {...props} items={data.allHomepageCarouselJson.nodes} />
    )}
  />
);
