import * as React from "react";
import * as carouselStyles from "./carousel.module.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "gatsby";
import { CarouselItemProps } from "./types";
import Heading from "../basic-components/heading/heading";
import Container from "../container/container";
import Button from "../basic-components/button/button";
import { GatsbyImage, withArtDirection } from "gatsby-plugin-image";

const CarouselItem: React.FC<CarouselItemProps> = ({
  item,
}: CarouselItemProps) => {
  const {
    id,
    title,
    description,
    link: { path, text },
    imageDesktop,
    imageTablet,
    imageMobile,
  } = item;
  const images = withArtDirection(imageMobile.childImageSharp.gatsbyImageData, [
    {
      media: "(min-width: 1000px)",
      image: imageDesktop.childImageSharp.gatsbyImageData,
    },
    {
      media: "(min-width: 520px)",
      image: imageTablet.childImageSharp.gatsbyImageData,
    },
  ]);

  return (
    <div className={carouselStyles.item}>
      <div className={carouselStyles.content}>
        <Container>
          <Container className={id === "2" ? carouselStyles.contextId2 : id === "1" ? carouselStyles.contextId1 : carouselStyles.context}>
            <Link to={path} className={carouselStyles.description}>
              <Heading>{title}</Heading>
              <p>{description}</p>
            </Link>{
              path ?
                <Button className={carouselStyles.buttonHolder} to={path}>
                  {text}
                </Button>
                : <></>
            }
          </Container>
        </Container>
      </div>
      <GatsbyImage loading="lazy" image={images} alt={title + " görseli"} />
    </div>
  );
};

export default CarouselItem;
