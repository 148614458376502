// extracted by mini-css-extract-plugin
export var buttonHolder = "carousel-module--buttonHolder--j-hXv";
export var container = "carousel-module--container--ug3FB";
export var content = "carousel-module--content--qDPHs";
export var context = "carousel-module--context--qWEGK";
export var contextId1 = "carousel-module--contextId1--Er4De";
export var contextId2 = "carousel-module--contextId2--mleZd";
export var description = "carousel-module--description--Ut5bo";
export var item = "carousel-module--item--vE5Xu";
export var leftarrow = "carousel-module--leftarrow--k86y2";
export var product = "carousel-module--product--owPi1";
export var rightarrow = "carousel-module--rightarrow--8DsZh";