import * as React from "react";
import Layout from "../components/layout/layout";
import { Carousel } from "../components/carousel/carousel";
import { ProductBar } from "../components/product-bar/product-bar";
import { Helmet } from "react-helmet";

const IndexPage: React.FC = () => {
  return (
    <Layout
      stickyHeader={false}
      
    >
      <Helmet>
        <meta
          name="facebook-domain-verification"
          content="oov13ac4pkyzk35miaf4hzuvlf9yjt"
        />
        <script type="application/ld+json">
          {`
          {"@context": "https://schema.org","@type": "Organization","name": "Bisolvon","alternateName": "Bisolvon","url": "https://www.bisolnatur.com.tr/","logo": "https://www.bisolnatur.com.tr/static/Bisolvon-logo-6c5c1ee0a66f8d7b422b572106dc7732.png","contactPoint": {"@type": "ContactPoint","telephone": "(0212) 339 10 00","contactType": "customer service","areaServed": "TR","availableLanguage": "Turkish"},"sameAs": ["https://www.instagram.com/bisolvontr/","https://www.facebook.com/bisolvontr/"]}
          `}
        </script>
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "headline": "Çocuğunuz ve Kendiniz İçin Bisolnatur: Doğal içerikli öksürük şurubu",
            "name" : "Doğal içerikli öksürük şurubu Bisolnatur",
            "description": "Çocuğunuz ve kendiniz için tüm öksürük tiplerine karşı etkili ve doğal içerikli öksürük şurubu Bisolnatur, kuru ve balgamlı öksürüğü rahatlatır. Boğaz tahrişini engeller ve balgamın atılmasını kolaylaştırır.",
            "url": "https://www.bisolnatur.com.tr/",
            "alternatename":"bisolnatur.com.tr/",
            "legalName": "Bisolnatur",
            "logo": "https://www.bisolnatur.com.tr/logo.svg",
            "brand": {
               "@type": "Organization",
               "name": "Bisolnatur",
               "url": "https://www.bisolnatur.com.tr"
             },
            "email": "tuketicisagligidanisma@sanofi.com",
            "telephone": "+902123391000",
            "sameAs": [
            "https://www.facebook.com/BisolnaturTR/",
            "https://www.instagram.com/bisolnaturtr/",
            "https://twitter.com/BisolnaturTR",
            "https://www.youtube.com/channel/UCrqvlKkvo_vDPxOOIxBt7Bg"
            ]
          }
          `}
        </script>
      </Helmet>
      <Carousel />
      <ProductBar />
    </Layout>
  );
};

export default IndexPage;
